//index.js
import React, {useState} from "react";
import ReactDOM from "react-dom/client";
import {Button, TextField} from "@mui/material";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const api_url = "http://api.0721.uno:5000";


function App() {
    const [data, setData] = useState("Click GET button!");
    const [name, setName] = useState("");

    const onBtnClick = () => {
        console.log(name);
        fetch(api_url + "/query/" + name)
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                setData("kills: " + data["kills"] + " deaths: " + data["deaths"]);
            }
        );
    };

    const onBtnClick2 = () => {
        fetch(api_url + "/token_json")
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                setData(data["token"]);
            })
    }

    return (
        <div className="absolute top-0 bottom-0 left-0 right-0 m-auto bg-white rounded border shadow p-6 h-fit w-fit">
            <h5 className="text-3xl font-bold mb-4 mt-0">Test</h5>
            <p className="text-gray-700 text-sm">{data}</p>
            <br/>
            <TextField
                id="outlined-controlled"
                label="游戏名称"
                variant="standard"
                value={name}
                onChange={(event) => {
                    setName(event.target.value);
                }}
            />
            <br/><br/>
            <Button variant="contained" onClick={onBtnClick}>GET!</Button>
            <br/><br/>
            <Button variant="contained" onClick={onBtnClick2}>GET 2!</Button>
        </div>
    );
}

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);
